import * as commonRoutes from "./constants";
import * as validRegexRules from "../lib/manageFields";
import validator from "validator";
import { paymentApi } from "../services/monek.service";
import { cleanPhoneNumber } from "../common/phoneNumberValidation";
import log from "loglevel";
import { getChatsWithUser, findDocumentByField } from "./firebase-query";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-toastify";
import { findHPICheck } from "../components/reusable/HPICheck";
import { convertDataToSchema } from "../pages/App/AddCar/HpiAndMot/jsonGenerator";

export const dynamicNumberDropdown = (length) => {
  const options = [];
  for (let i = 1; i <= length; i++) {
    options.push({ value: i, label: i });
  }
  return options;
};

export const noOfOwnersDropdown = (ownerCount) => {
  return [
    { value: false, label: ownerCount },
    { value: true, label: "Not Disclosed" },
  ];
};

export const EmptyImageList = (noImg, list, listLimit) => {
  if (list.length <= 4) {
    listLimit = 4;
  } else {
    listLimit = list.length;
  }
  let number = listLimit - list.length;
  const myArray =
    number > 0 ? [...Array(number).keys()].map((num) => num + 1) : [];
  return myArray.map((i) => (
    <li key={i.key}>
      <div className="upload-img no-img"></div>
    </li>
  ));
};

export const numberWithCommas = (e, handleChange) => {
  const formattedNumber = new Intl.NumberFormat("en-GB").format(
    e.target.value.replace(/,/g, ""),
  );
  e.target.value = formattedNumber.replace(/^,/, "");
  if (e.target.value === "NaN") {
    return;
  }
  if (e.target.value === "0") {
    e.target.value = "";
  }
  handleChange(e);
};

export const formatNumberWithCommas = (inputValue = 0) => {
  if (inputValue) {
    const formattedNumber = new Intl.NumberFormat("en-GB", {
      maximumFractionDigits: 0,
    }).format(inputValue.toString().replace(/,/g, ""));
    let newValue = formattedNumber.replace(/^,/, "");
    if (newValue === "0" || newValue === "NaN") {
      newValue = "";
    }
    return newValue;
  }
  return "";
};

export const formatNumberWithCommasWith0 = (inputValue = 0) => {
  const formattedNumber = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 0,
  }).format(inputValue.toString().replace(/,/g, ""));
  let newValue = formattedNumber.replace(/^,/, "");
  if (newValue === "NaN") {
    newValue = "";
  }
  return newValue;
};

export const formatPriceWithCommas = (inputValue = 0) => {
  // Convert the input value to a number
  const numericValue = parseFloat(inputValue.toString().replace(/,/g, ""));
  if (isNaN(numericValue)) {
    return "";
  }
  const formattedNumber = new Intl.NumberFormat("en-GB", {
    maximumFractionDigits: 2, //for handling decimal value
  }).format(numericValue);
  return formattedNumber;
};

export const CalcPercentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};

export const addCarDetailsLocalStorage = (data) => {
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(data));
};

export const updateUserExpectedValue = (fieldValue) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, userExpectedValue: fieldValue };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const updateQuickSaleStatus = (fieldValue) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, quickSale: fieldValue };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const updateOpenToSwapStatus = (value) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, openToSwap: value };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const updateAddedAccessories = (fieldValue) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, addedAccessories: fieldValue };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const updateHpiAndMotValues = (fieldValue) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, hpiAndMot: fieldValue };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const updateCarId = (fieldValue) => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  const resObj = { ...res, carId: fieldValue };
  return localStorage.setItem("localStoreCarDetails", JSON.stringify(resObj));
};

export const getCarId = () => {
  const res = JSON.parse(getCarDetailsLocalStorage());
  return res?.carId;
};

export const getCarDetailsLocalStorage = () => {
  return localStorage.getItem("localStoreCarDetails") || "{}";
};

export const delCarDetailsLocalStorage = () => {
  return localStorage.removeItem("localStoreCarDetails") || "{}";
};

export const addPaginationKeyLocalStorage = (data) => {
  return localStorage.setItem("PaginationKey", data);
};

export const addViewdCarsToLocalStorage = (data) => {
  return localStorage.setItem("viewedCars", data);
};

export const permissionCheck = ({
  user,
  navigate,
  regStatus,
  setShow,
  setShowQrPopUp,
  setShowInfoModal,
  initialLoginStatus,
}) => {
  const userType = user?.userType;
  const firstName = user?.firstName;
  const payAsYouGoActiveStatus =
    user?.trader?.payAsYouGo !== null &&
    user?.trader?.payAsYouGo?.filter((item) => item.status === "active")
      .length >= 1;
  let navigateRoute;
  switch (true) {
    case firstName === "":
      log.debug(
        "permissionCheck--social login with registration incompleted user",
      );
      setShow(true);
      break;
    case userType === commonRoutes.PRIVATE:
      log.debug("permissionCheck--private user login");
      setShowQrPopUp(true);
      break;
    case regStatus:
      log.debug("permissionCheck--dealer signup");
      navigateRoute = commonRoutes.SUBSCRIPTION_ROUTE;
      break;
    case user?.trader?.status === "pending" && !regStatus:
      log.debug("permissionCheck--account not approved");
      setShowInfoModal(true);
      break;
    case !payAsYouGoActiveStatus &&
      user?.trader?.subscription === null &&
      user?.trader?.upgradeSubscription === null:
      log.debug(
        "permissionCheck--Pay as you go expired - no upgrade requests are there",
      );
      navigateRoute = commonRoutes.SUBSCRIPTION_ROUTE;
      break;
    case user?.trader?.status === "active" &&
      ((user?.trader?.payAsYouGo &&
        user?.trader?.payAsYouGo?.filter((item) => item.status === "active")
          .length > 0) ||
        user?.trader?.subscription?.status === "active"):
      log.debug(
        "permissionCheck--if an active pay as you go plan exists or an active subscription exists, then no need to verify other conditions- redirect to add car page",
      );
      navigateRoute =
        initialLoginStatus === true
          ? commonRoutes.CAR_VALUATION_CHECK_ROUTE
          : commonRoutes.DASHBOARD_HOME_ROUTE;

      break;
    case user?.trader?.upgradeSubscription?.isInitialSubscription === false &&
      user?.trader?.payAsYouGo?.filter((item) => item.status === "active")
        .length < 1 &&
      (user?.trader?.subscription === null ||
        user?.trader?.subscription?.status === "cancelled"):
      log.debug(
        "permissionCheck--no Active plan exists. Existing pay as you go expired and an upgrade req is pending so waiting for approval of upgrade req",
      );
      setShowInfoModal(true);

      break;
    case user?.trader?.status === "active" &&
      user?.trader?.subscription === null &&
      user?.trader?.payAsYouGo === null &&
      user?.trader?.upgradeSubscription === null:
      log.debug("permissionCheck--redirect to subscription");
      navigateRoute = commonRoutes.SUBSCRIPTION_ROUTE;
      break;
    case user?.trader?.status === "active" &&
      user?.trader?.subscription === null &&
      (user?.trader?.upgradeSubscription?.isInitialSubscription === true ||
        user?.trader?.upgradeSubscription === null):
      log.debug(
        "permissionCheck--account approved but subscription request is pending",
      );
      setShowInfoModal(true);

      break;
    case (user?.trader?.payAsYouGo === null ||
      user?.trader?.payAsYouGo?.filter((item) => item.status === "active")
        .length < 1) &&
      user?.trader?.subscription?.type === "freeTrial" &&
      user?.trader?.upgradeSubscription?.isInitialSubscription === false &&
      (user?.trader?.subscription?.status === "expired" ||
        user?.trader?.subscription?.status === "cancelled"):
      log.debug(
        "permissionCheck--no Active pay as you go exists. Existing free trial expired. so waiting for the upcoming plan(in upgrade request) to approve",
      );
      setShowInfoModal(true);
      break;
    default:
      log.debug("permissionCheck--default redirection to subscription page");
      navigateRoute = commonRoutes.SUBSCRIPTION_ROUTE;
      break;
  }

  return navigate(navigateRoute);
};

export function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
}

export const responseCheck = (statusCode, message) => {
  switch (statusCode) {
    case "200":
      return message?.message;
    case "405":
      return message ? message : "Input value missing";
    default:
      return message ? message : "Something went wrong";
  }
};

export const inputWithAlphabetsOnly = (e) => {
  if (
    !(
      validator.isAlpha(e.key) ||
      (e.key === " " && e.target.value.length >= 1) ||
      (e.key === "-" && e.target.value.length >= 1)
    )
  ) {
    e.preventDefault();
  }
};

export const inputWithNumbersAndSpaceOnly = (e) => {
  if (!validRegexRules.numbersAndSpaceRegex.test(e.key)) {
    e.preventDefault();
  }
};

export const inputWithNoSpaces = (e) => {
  if (!validRegexRules.noSpaces.test(e.key)) {
    e.preventDefault();
  }
};

export const inputWithLetterHypen = (e) => {
  if (!validRegexRules.firstNameFormat.test(e.key)) {
    e.preventDefault();
  }
};
export const inputWithLetterSpaceHypen = (e) => {
  if (!validRegexRules.lastNameFormat.test(e.key)) {
    e.preventDefault();
  }
};

export const customHandleChange = ({
  event,
  validationRule,
  values,
  setValues,
}) => {
  if (validationRule) {
    setValues({
      ...values,
      [event.target.name]: event.target.value.trimStart(),
    });
  }
};

export const redirectPageAndSetState = ({
  navigate,
  routePage,
  setState,
  status,
}) => {
  setState(status);
  navigate(routePage);
};

export const closeModals = ({ setState, status }) => {
  setState(status);
};

export const dobCalender = () => {
  let today, dd, mm, yyyy;
  today = new Date();
  dd = today.getDate();
  mm = today.getMonth() + 1;
  yyyy = today.getFullYear() - 18;
  return new Date(yyyy + "/" + mm + "/" + dd);
};

export const getCurrentDate = () => {
  let today, dd, mm, yyyy;
  today = new Date();
  dd = String(today.getDate()).padStart(2, "0");
  mm = String(today.getMonth() + 1).padStart(2, "0");
  yyyy = today.getFullYear();
  let currentDate = `${yyyy}-${mm}-${dd}`;
  return currentDate;
};

export const handleSubmitPayAsYouGo = ({ plan, user, setLoading }) => {
  const inputParams = {
    amount: plan.amount * 100, //amount in pence to doller conversion
    amountDescription: plan.description,
    userId: user._id,
    userEmail: user.email,
    userPhone: commonRoutes.COUNTRY_CODE + cleanPhoneNumber(user.phone),
    planId: plan._id,
    traderId: user.traderId,
    planName: plan.name,
    sourceUrl: plan?.sourceUrl,
  };
  setLoading(true);
  paymentApi(inputParams)
    .then((res) => {
      const result = res.data.checkoutLink;
      window.location.href = result;
    })
    .catch(() => {
      setLoading(false);
    });
};

export const handleSubmitPremium = async ({
  planId,
  user,
  carSlugId,
  setLoading,
  sourceUrl,
  confirmModal,
}) => {
  const inputParams = {
    userId: user._id,
    userEmail: user.email,
    userPhone: commonRoutes.COUNTRY_CODE + cleanPhoneNumber(user.phone),
    paymentType: "premium",
    carId: carSlugId,
    planId: planId,
    traderId: user.traderId,
    sourceUrl: sourceUrl,
  };
  setLoading(true);
  try {
    const res = await paymentApi(inputParams);
    window.location.href = res.data.checkoutLink;
    return false;
  } catch (err) {
    log.error("payment link gen failed", { err });
    setLoading(false);
  } finally {
    setLoading(false);
    confirmModal(false);
  }
};

export const phoneNumberToUk = (input, withCountryCode = true) => {
  if (withCountryCode) {
    return input.replace(/(\d{2})(\d{4})(\d{4})/, "$1 $2 $3");
  }
  return input.replace(/(\d{4})(\d{6})/, "$1 $2");
};

export const hocForConditions = ({ condition, ifReturn, elseReturn }) => {
  if (condition) {
    return ifReturn;
  } else {
    return elseReturn;
  }
};

export const uploadPhotosToArray = (carData) => {
  const uploadImages = [];
  if (carData?.uploadPhotos) {
    Object.entries(carData.uploadPhotos).forEach(([key, value]) => {
      uploadImages.push(...value);
    });
  }
  return uploadImages;
};

export const mapAccessoriesToDbFormat = (accessories) => {
  let listed = accessories?.listed || [];
  let notListed = accessories?.notListed || [];
  listed = listed.map(({ id, name, exist, ...rest }) => ({ id, name, exist }));
  notListed = notListed.map(({ name, ...rest }) => ({ name }));
  return { listed, notListed };
};

export const unBlokChatUser = async ({
  blockedUserObj,
  user,
  carSlugId,
  setParentLoader,
  setShowConfirmModal,
  navigate,
  updateDocument,
  navigationPath,
}) => {
  const userId = user?.traderId;
  let previous = await findDocumentByField(
    "chat_info",
    "groupId",
    blockedUserObj?.groupId,
  );
  previous = previous[0];
  let blockedUsr =
    previous.admin.userId === userId
      ? previous.receiver.userId
      : previous.admin.userId;
  let collections = await getChatsWithUser(userId, blockedUsr); //("chat_info", "groupId", userId, "groupUsers", blockedUsr)
  let blockedArr = previous.blockedUsers;
  let blockedUsers = blockedArr.filter((item) => item !== userId);
  if (collections.length > 0) {
    await Promise.all(
      collections.map((collection) =>
        updateDocument("chat_info", collection.groupId, {
          blockedUsers: blockedUsers,
        }),
      ),
    );
  }
  toast.success("User unblocked successfully");
  setShowConfirmModal(false);
  navigationPath === ""
    ? navigate(`/app/chat/${carSlugId}?makeAnOffer=true`)
    : navigate(navigationPath);
  setTimeout(() => {
    // Show toast message
    navigate(0);
    setParentLoader(false);
  }, 2000);
};

export const makeAnOfferStatusCheck = ({
  blockedUserStatus,
  setShowConfirmModal,
  carSlugId,
  navigate,
  setOfferLoading,
  fromFunc,
}) => {
  const navRoute = fromFunc === "chat" ? "" : "?makeAnOffer=true";
  if (blockedUserStatus) {
    setOfferLoading(false);
    setShowConfirmModal(true);
  } else {
    setOfferLoading(false);
    navigate(`/app/chat/${carSlugId}${navRoute}`);
  }
};

export const blockedUserStatusCheck = async ({
  findDocumentByFieldArrayContains,
  traderId,
  currentUserId,
}) => {
  //Here getting the results of users which are blocked by the current user and the other user exists in the groupusers
  //ie, chat is between these two users and opponent is blocked by the current user
  return await findDocumentByFieldArrayContains(
    "chat_info",
    "groupUsers",
    traderId,
    "blockedUsers",
    currentUserId,
  );
};

export const checkEditOrAdd = () => {
  if (window.location.href.includes("edit")) {
    return "edit";
  }
  return "add";
};

const modifyFilterMileage = (filterObj) => {
  if (filterObj.mileage.min === commonRoutes.FILTER_MILEAGE_MIN) {
    delete filterObj.mileage.min;
  }
  if (filterObj.mileage.max === commonRoutes.FILTER_MILEAGE_MAX) {
    delete filterObj.mileage.max;
  }
};

const modifyFilterYear = (filterObj) => {
  const maxValue = new Date().getFullYear();
  if (filterObj.year.min === commonRoutes.FILTER_YEAR_MIN) {
    delete filterObj.year.min;
  }
  if (filterObj.year.max === maxValue) {
    delete filterObj.year.max;
  }
};

const modifyFilterEngineSize = (filterObj) => {
  if (filterObj.engineSize.min === commonRoutes.FILTER_ENGINE_SIZE_MIN) {
    delete filterObj.engineSize.min;
  }
  if (filterObj.engineSize.max === commonRoutes.FILTER_ENGINE_SIZE_MAX) {
    delete filterObj.engineSize.max;
  }
};

const modifyFilterPrice = (filterObj) => {
  if (filterObj.priceFilter.min === commonRoutes.FILTER_PRICE_MIN) {
    delete filterObj.priceFilter.min;
  }
  if (filterObj.priceFilter.max === commonRoutes.FILTER_PRICE_MAX) {
    delete filterObj.priceFilter.max;
  }
};

export const modifyFilterLimits = (searchFilter) => {
  if (searchFilter.mileage) {
    modifyFilterMileage(searchFilter);
  }

  if (searchFilter.year) {
    modifyFilterYear(searchFilter);
  }

  if (searchFilter.engineSize) {
    modifyFilterEngineSize(searchFilter);
  }

  if (searchFilter.priceFilter) {
    modifyFilterPrice(searchFilter);
  }

  return searchFilter;
};

export const textTransormation = (val) => {
  //Transform only first letter to Uppercase
  let entered = val || "";
  let firstLetter = entered.charAt(0).toUpperCase();
  let rest = entered.substring(1);
  return firstLetter + rest;
};

export const firebaseRealTimeListner = ({
  collectionName,
  documentId,
  setPlanCancelStatus,
  setPayAsYouGoActiveCount,
}) => {
  log.debug("firebaseRealTimeListner invoked");
  // Add a listener to the collection
  const unsubscribe = onSnapshot(doc(db, collectionName, documentId), (doc) => {
    setPlanCancelStatus(doc.data()?.subscriptionStatus);
    setPayAsYouGoActiveCount(doc.data()?.payAsYouGo);
  });

  // Clean up the listener when the component unmounts
  return () => unsubscribe();
};

export const activePlanStatusCheck = ({ currentPath, traderObj, navigate }) => {
  const targetPathFailure = "/app/payments/failure";
  const targetPathSuccess = "/app/payments/success";
  const isMatchingPath = currentPath === targetPathFailure;
  const payAsYouGoCount = traderObj?.payAsYouGo?.length;
  const activePayAsYouGoCount = traderObj?.payAsYouGo?.filter(
    (item) => item.status === "active",
  ).length;
  const latestPayAsYouGo = payAsYouGoCount
    ? traderObj?.payAsYouGo[payAsYouGoCount - 1]
    : [];
  log.debug(
    "activePlanStatusCheck-- activePayAsYouGoCount<1 && latestPayAsYouGo.status==='failed' && isMatchingPath",
    activePayAsYouGoCount < 1,
    latestPayAsYouGo.status === "failed",
    isMatchingPath,
  );
  if (targetPathSuccess) {
    log.debug("activePlanStatusCheck success");
    return;
  }
  if (
    activePayAsYouGoCount < 1 &&
    latestPayAsYouGo.status === "failed" &&
    isMatchingPath
  ) {
    log.debug("activePlanStatusCheck failure");
    return;
  }

  if (
    traderObj?.payAsYouGo?.filter((item) => item.status === "active").length <
      1 &&
    currentPath !== "/app/subscription" &&
    (traderObj?.subscription === null ||
      traderObj?.subscription?.status !== "active") &&
    traderObj?.upgradeSubscription === null
  ) {
    log.debug("activePlanStatusCheck redirect to subscription");
    navigate(commonRoutes.SUBSCRIPTION_ROUTE);
  }
};

export const checkRedirectToBaseRoute = ({ user, currentPath, navigate }) => {
  //WSAC-2926
  //free trial expired without pay as you go-- it should show the 'upcoming plan is pending' message
  if (
    currentPath !== commonRoutes.BASE_ROUTE &&
    user?.trader?.subscription !== null &&
    user?.trader?.subscription?.status === "expired" &&
    user?.trader?.status === "active" &&
    (user?.trader?.payAsYouGo === null ||
      (user?.trader?.payAsYouGo !== null &&
        user?.trader?.payAsYouGo?.filter((item) => item.status === "active")
          .length <= 0))
  ) {
    navigate(commonRoutes.BASE_ROUTE);
  }
};

export const historyCheckMarkers = ({
  hpiHistoryCheckObj,
  prevOwners,
  allClear,
  setAllClear,
  setAllClearMessage,
}) => {
  const res = findHPICheck(
    convertDataToSchema(hpiHistoryCheckObj, { previousOwner: prevOwners }),
    setAllClear,
    allClear,
  );
  setAllClearMessage(res?.message);
  return allClear;
};

export const trimFirstZeroFromPhoneNumber = (phoneNumber) => {
  const phoneNumberSplit =
    phoneNumber.split(commonRoutes.COUNTRY_CODE)[1] || phoneNumber;
  const phoneNumberWithoutZero = phoneNumberSplit.startsWith("0")
    ? phoneNumberSplit.slice(1)
    : phoneNumberSplit;
  return phoneNumberWithoutZero;
};

export const phoneNumberMaxLengthRegardingZero = (
  e,
  phoneRef,
  setPhoneMaxLength,
) => {
  const startWithZero = e.target?.value?.startsWith(0);
  const currentCursorPositionStart = phoneRef.current.selectionStart;
  const onlyFirstDigitZero =
    (e.keyCode === 48 || e.keyCode === 96) && currentCursorPositionStart === 0;
  const inputLimit = onlyFirstDigitZero || startWithZero ? 12 : 11;
  setPhoneMaxLength(inputLimit);
};

export const handleMobileLandlineErrors = ({ mobile, landline, setError }) => {
  const phoneEmpty = mobile === "" || mobile === undefined;
  const mobileEmpty = landline === "" || landline === undefined;
  const showError = phoneEmpty && mobileEmpty;
  if (showError) {
    setError("companyContact", {
      message: commonRoutes.PHONE_AND_TEL_REQUIRED,
    });
    setError("phone", { message: commonRoutes.PHONE_AND_TEL_REQUIRED });
  }
};

export const getdragImgId = (imgArray, id) => {
  return imgArray.findIndex((task) => {
    let key = task.src.split("/").splice(-2)[1];
    return key === id;
  });
};

export const sortedDragImages = (imgArray, event) => {
  const { active, over } = event;
  const activeIndex = getdragImgId(imgArray, active.id);
  const overIndex = getdragImgId(imgArray, over.id);
  // Swap items in sortedItems array
  const newSortedItems = [...imgArray];
  [newSortedItems[activeIndex], newSortedItems[overIndex]] = [
    newSortedItems[overIndex],
    newSortedItems[activeIndex],
  ];
  return newSortedItems;
};
export const checkingInternetConnection = () => {
  const isOnline = navigator.onLine;
  if (isOnline) {
    log.debug("Internet is connected");
    return true;
  } else {
    log.debug("Internet connection lost");
    return false;
  }
};

export const formatToNumber = (value) => {
  let val = value.split(".")[0].replace(/\D/g, "");
  val = parseInt(val);
  if (isNaN(val)) {
    return "";
  }
  return val;
};

export const handleMaxLengthPhone = (
  inputValue,
  phoneRefObj,
  setMaxLength,
  phoneInput,
) => {
  let inputLimit;
  // Regular expression to match non-digit characters
  const regex = /\D/g;
  const startWithCountryCode = phoneInput?.startsWith(
    commonRoutes.COUNTRY_CODE,
  );
  const newInputValue =
    phoneInput.length > 0 && !startWithCountryCode
      ? `${phoneInput}${inputValue}`
      : inputValue;
  // Match and count non-digit characters
  const spaceCount = (newInputValue.match(regex) || []).length;
  const startWithZero = newInputValue?.startsWith(0);
  //to fix - full digits not pasted to the input field if it has more than one spaces
  if (spaceCount > 0) {
    inputLimit = startWithZero
      ? 12 + parseInt(spaceCount)
      : 11 + (parseInt(spaceCount) - 1);
  } else {
    inputLimit = startWithZero ? 12 : 11;
  }
  phoneRefObj.current.setAttribute("maxlength", inputLimit);
  setMaxLength(inputLimit);
};

export const checkPhoneInputAllZeros = (value) => {
  const allZeroesRegex = /^0*$/;
  return value[0] === "0" && value.length >= 11 && allZeroesRegex.test(value);
};

export const checkPhoneVerification = (val1, val2, status) => {
  // Regular expression to match either '+91' or a leading '0' at the start of the string
  const prefix1 = commonRoutes.COUNTRY_CODE;
  const prefix2 = "0";
  const prefixPattern = new RegExp(`^(?:\\${prefix1}|${prefix2})`);
  const newVal1 = val1.replace(prefixPattern, "");
  const newVal2 = val2.replace(prefixPattern, "");
  return newVal1 === newVal2 && status;
};
